import Logger from "js-logger";
import { JWTHelper, LocaleHelper, RequestHelper, httpService } from "@coworker/sharedlibrary";
import { LogModel } from "@coworker/sharedlibrary";
import { browserName, browserVersion, deviceType, getUA, isChrome, mobileModel, osName, osVersion } from "react-device-detect";

import packageJson from "../../package.json";
import { isExternalClientUrl } from "../helpers/externalUrlHelper";

const AppLogger = {
	isLogging: false,
	logs: [],
	store: {},
	init: store => {
		AppLogger.store = store;
		const isExternal = isExternalClientUrl();
		const timeOut = isExternal ? 10 : 30000;
		setInterval(() => {
			const ifConnected = window.navigator.onLine;
			if (ifConnected) {
				const token = RequestHelper.getToken();
				if (!AppLogger.isLogging && JWTHelper.isTokenValid(token)) {
					if (AppLogger.logs.length > 0) {
						let temp = [...AppLogger.logs];
						AppLogger.logs = [];
						// eslint-disable-next-line no-unused-vars
						// eslint-disable-next-line no-undef
						// if (process.env.NODE_ENV === "production") {
						AppLogger.isLogging = true;
						logToSplunk(temp)
							.then(res => {
								if (res.error) {
									AppLogger.logs = AppLogger.logs.concat(temp);
								}
								AppLogger.isLogging = false;
							})
							.catch(() => {
								AppLogger.isLogging = false;
								AppLogger.logs = AppLogger.logs.concat(temp);
							});
						// }
					}
				}
			}
		}, timeOut);
		Logger.useDefaults({
			defaultLevel: Logger.ERROR | Logger.WARN | Logger.TRACE | Logger.INFO
		});

		let consoleHandler = Logger.createDefaultHandler({
			// eslint-disable-next-line no-unused-vars
			formatter: function(messages) {
				messages[0] = updateModel(store, messages[0]);
			}
		});

		// eslint-disable-next-line no-unused-vars
		let myHandler = function(messages) {};

		Logger.setHandler(function(messages, context) {
			// eslint-disable-next-line no-undef
			if (process.env.NODE_ENV === "development") {
				consoleHandler(messages, context);
			}

			AppLogger.logs.push(updateModel(store, messages[0]));
			myHandler(messages, context);
		});
	},
	warn: (message, event, properties) => {
		let log = new LogModel("WARN", message, event, properties);
		if (!log.Success) log.Success = true;
		Logger.warn(log);
	},
	info: (message, event, properties) => {
		let log = new LogModel("INFO", message, event, properties);
		if (!log.Success) log.Success = true;
		Logger.info(log);
	},
	error: (message, event, properties) => {
		let log = new LogModel("ERROR", message, event, properties);
		if (!log.Success) log.Success = false;
		Logger.error(log);
	},
	debug: message => {
		let log = new LogModel("ERROR", message);

		Logger.debug(log);
	}
};
const updateModel = (store, logData) => {
	const { locale } = store.getState().localeController;
	const countryCode = LocaleHelper.getCountryCode(locale);
	let { userId, buCode, sessionId } = store.getState().appController;

	let log = {
		...logData,
		Platorm: navigator.platform === "Win32" ? "Windows" : navigator.platform,
		BrowserLanguage: navigator.language,
		BrowserName: navigator.appCodeName,
		Locale: locale,
		IsRdtDevice: getUA.toUpperCase().includes("CT40") || getUA.toUpperCase().includes("D75E")
	};
	if (userId && userId !== "") {
		log.SystemUserId = userId;
	} else {
		let user = RequestHelper.getLoggedInUser();
		if (user && user.ikealegacyuid) {
			log.SystemUserId = user.ikealegacyuid;
		}
	}
	if (buCode && buCode !== "") {
		log.BuCode = buCode;
	}
	if (countryCode && countryCode !== "") {
		log.CountryCode = countryCode;
	}
	if (isExternalClientUrl()) {
		log.ExternalClient = true;
		log.ExternalClientName = "iSell";
		log.ExternalCustomer = "iSell";
	}
	log.SessionId = sessionId;
	log.AppVersion = packageJson.version;

	log.Origin = window.location.origin;
	let logdata = {
		time: new Date().getTime(),
		host: "salja",
		source: "salja-web",
		event: { ...log }
	};
	return logdata;
};
export default AppLogger;

const logToSplunk = async logs => {
	try {
		let datas = [];
		logs.forEach(log => {
			datas.push(log);
		});

		let reponse = await httpService.post("/api/splunklog", {
			body: datas,
			headers: {
				Authorization: `Bearer ${RequestHelper.getToken()}`
			}
		});
		return Promise.resolve(reponse);
	} catch (error) {
		return Promise.reject(error);
	}
};

function logStartup(userid, sessionId, isExternal) {
	let browserData = {
		browserVersion,
		browserName,
		deviceType,
		mobileModel,
		isChrome,
		osName,
		osVersion
	};
	AppLogger.info("Application Start", "ApplicationStart", {
		Device: getUA,
		IsRdtDevice: getUA.toUpperCase().includes("CT40") || getUA.toUpperCase().includes("D75E"),
		Platorm: navigator.platform === "Win32" ? "Windows" : navigator.platform,
		BrowserLanguage: navigator.language,
		BrowserName: navigator.appCodeName,
		SessionId: sessionId,
		...browserData,
		ExternalClient: isExternal || false,
		SystemUserId: userid
	});
}

export { logStartup };
