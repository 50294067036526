import * as ObjectHelper from "@coworker/sharedlibrary/src/helpers/objectHelper";
import jwt_decode from "jwt-decode";
import { StorageKeys } from "@coworker/sharedlibrary/src/constants/dfpKey";
const { tokenKey, localeKey, authconfigKey, tokenResponseKey, overidedStoreKey } = StorageKeys;
export const getLoggedInUser = () => {
	try {
		let user;
		var token = getToken();
		if (token) {
			user = jwt_decode(token);
			return user;
		}
		var response = getTokenResponse();
		if (response) {
			user = response.user;
			return user;
		}
		return undefined;
	} catch {
		//
	}
};

export const getClientId = () => {
	try {
		let user;
		var token = getToken();
		if (token) {
			user = jwt_decode(token);
			if (user) {
				return user.azp;
			}
		}
		return undefined;
	} catch {
		//
	}
};

export const getOverridedStore = () => {
	if (localStorage.getItem(overidedStoreKey) === "undefined" || localStorage.getItem(overidedStoreKey) === "") {
		return;
	}
	return localStorage.getItem(overidedStoreKey);
};
export const setOverridedStore = store => {
	return localStorage.setItem(overidedStoreKey, store);
};

export const getToken = () => {
	// const vToken = localStorage.getItem(tokenKey);
	// if (vToken) {
	// 	let user = jwt_decode(vToken);
	// 	if (user) {
	// 		console.log("RequestHelper.getToken - ClientId from Token: ", user.azp + " - " + user.application_name);
	// 	}
	// }
	// return vToken;
	return localStorage.getItem(tokenKey);
};
export const setToken = token => {
	try {
		if (token) {
			let user = jwt_decode(token);
			if (user) {
				console.log("RequestHelper.setToken - ClientId from Token: ", user.azp + " - " + user.application_name);
				localStorage.setItem(tokenKey, token);
			}
		}
	} catch {
		//
	}
};

export const getLocale = () => {
	// console.log(localeKey, localStorage.getItem(localeKey));
	return localStorage.getItem(localeKey);
};

export const setLocale = val => {
	return localStorage.setItem(localeKey, val);
};
export const setAuthConfig = auth => {
	return localStorage.setItem(authconfigKey, JSON.stringify(auth));
};

export const getAuthConfig = () => {
	var authval = localStorage.getItem(authconfigKey);
	if (!ObjectHelper.isNil(authval)) {
		return JSON.parse(authval);
	}
	return undefined;
};

export const setTokenResponse = tokenData => {
	return localStorage.setItem(tokenResponseKey, JSON.stringify(tokenData));
};

export const getTokenResponse = () => {
	var val = localStorage.getItem(tokenResponseKey);
	if (!val) {
		return null;
	}
	return JSON.parse(val);
};
