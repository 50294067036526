import jwt_decode from "jwt-decode";
import moment from "moment";
import { GUID, RequestHelper, APIEndpoints, httpService } from "@coworker/sharedlibrary";
import { isEdge, isIE } from "react-device-detect";
// import { isExternalClientUrl } from "../helpers/externalUrlHelper";
import SalesCoworkerConfiguration from "../store/salesCoworkerConfiguration";
export default class AzureAuthService {
	constructor(authConfig) {
		this.applicationConfig = authConfig;
	}
	logOut = () => {
		const logoutUrl = `${this.applicationConfig.authority}/oauth2/v2.0/logout?post_logout_redirect_uri=${window.location.origin}`;
		window.location = logoutUrl;
	};
	login = () => {
		let devredirectUri = "http://localhost:3000";
		let redirectUri = devredirectUri;

		var stateData = {
			state: window.location.href,
			verifier: "azure"
		};
		var stateurl = btoa(JSON.stringify(stateData));
		redirectUri = window.location.origin;

		var graphScope = this.applicationConfig.graphScopes[0];
		// var graphScope_v2 = this.applicationConfig.graphScopes_v2[0];

		var scope = `offline_access+${graphScope}+openid+profile`;
		// var scope = `offline_access+openid+profile`;

		var tenant = graphScope.includes("ppe") ? "iweofpp.onmicrosoft.com" : "iweof.onmicrosoft.com";
		// var tenant = !graphScope_v2.includes("prod") ? "iweofpp.onmicrosoft.com" : "iweof.onmicrosoft.com";
		tenant = this.applicationConfig.azureTenantId;

		var domainHint = graphScope.includes("ppe") ? "ikeadt.com" : "ikea.com";
		// var domainHint = !graphScope_v2.includes("prod") ? "ikeadt.com" : "ikea.com";

		// const url = `${this.applicationConfig.authority}/oauth2/v2.0/authorize?response_type=code&client_id=${this.applicationConfig.client_id}&redirect_uri=${redirectUri}&scope=${scope}&state=${stateurl}&nonce=${GUID.Guid()}&tenant=${tenant}&domain_hint=${domainHint}`;
		const url = `${this.applicationConfig.authority_v2.replace(
			"{tenantid}",
			this.applicationConfig.azureTenantId
		)}/oauth2/v2.0/authorize?response_type=code&client_id=${
			this.applicationConfig.client_id_v2
		}&redirect_uri=${redirectUri}&scope=${scope}&state=${stateurl}&nonce=${GUID.Guid()}&tenant=${tenant}&domain_hint=${domainHint}`;

		if (isEdge || isIE) {
			window.location.href = url;
		} else {
			window.location = url;
		}
	};

	async getAccessToken(code, referer) {
		var envurl = APIEndpoints.accessToken;
		var data = await httpService.post(envurl, {
			body: {
				code: code,
				refresh: false,
				referer: referer,
				provider: "azure"
			}
		});
		if (data.access_token) {
			RequestHelper.setToken(data.access_token);
			RequestHelper.setTokenResponse(data);
			return data;
		}
	}

	async refreshAccessToken() {
		var envurl = APIEndpoints.accessToken;
		var tokenReponse = RequestHelper.getTokenResponse();
		var response = await httpService.post(envurl, {
			body: {
				code: tokenReponse.refresh_token,
				refresh: true,
				referer: window.location.origin,
				provider: "azure"
			}
		});
		if (response.access_token) {
			var tokenData = response;
			RequestHelper.setTokenResponse(response);
			RequestHelper.setToken(response.access_token);
			SalesCoworkerConfiguration.setUserToken(response.access_token);
			return tokenData;
		}
	}

	async checkTokenValid() {
		// Let's try to refresh, even if it is External
		// if (isExternalClientUrl()) {
		// 	return true;
		// }

		const clientId = RequestHelper.getClientId();
		console.log("azureAuthService.checkTokenValid - ClientId from Config: ", this.applicationConfig.client_id_v2);
		console.log("azureAuthService.checkTokenValid - ClientId from Token: ", clientId);
		if (this.applicationConfig.client_id_v2 !== clientId) {
			console.log("azureAuthService.checkTokenValid - False: ");

			return false;
		}

		var token = RequestHelper.getToken();

		if (this.checkIsExpired(token)) {
			try {
				var response = await this.refreshAccessToken();
				if (response.access_token) {
					RequestHelper.setTokenResponse(response);
					RequestHelper.setToken(response.access_token);
					SalesCoworkerConfiguration.setUserToken(response.access_token);
					return true;
				}
			} catch (error) {
				return false;
			}
		}
		return true;
	}

	checkIsExpired(token) {
		var tokenDecode = jwt_decode(token);
		var todate = moment();
		var exp = moment.unix(tokenDecode.exp);

		return todate.isAfter(exp);
	}
}
